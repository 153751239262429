import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Maui from "./Maui";
import { Link } from "react-router-dom";

export default function Blogs() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="blogs-container">
      <h1 className="blogs-title">Surf Blog Adventures</h1>
      <div className="blogs-grid">
        <Link to="/blogs/surf-lessons" className="blog-link">
          <div className="blog-card">
            <div className="blog-image surfing-lessons-image">
              <img src="/images/surf_lessons.jpg" alt="Surfing Lessons" />
            </div>
            <div className="blog-content">
              <h2>Surfing Lessons Guide</h2>
              <p>Learn the basics of surfing and how to catch a wave</p>
              <span className="read-more">Read More →</span>
            </div>
          </div>
        </Link>

        <Link to="/blogs/maui" className="blog-link">
          <div className="blog-card">
            <div className="blog-image maui-image">
              <img src="/images/hawaii.jpg" alt="Maui Surfing" />
            </div>

            <div className="blog-content">
              <h2>Maui Surfing Guide</h2>
              <p>Discover the best surfing spots and lessons in Maui, Hawaii</p>
              <span className="read-more">Read More →</span>
            </div>
          </div>
        </Link>

        <Link to="/blogs/huntington" className="blog-link">
          <div className="blog-card">
            <div className="blog-image huntington-image">
              <img src="/images/huntington.jpg" alt="Huntington Beach" />
            </div>
            <div className="blog-content">
              <h2>Huntington Beach</h2>
              <p>
                Unraveling the Coastal Charm: Exploring the Natural Beauty of
                Huntington State Beach
              </p>
              <span className="read-more">Read More →</span>
            </div>
          </div>
        </Link>

        <Link to="/blogs/losangeles" className="blog-link">
          <div className="blog-card">
            <div className="blog-image losangeles-image">
              <img src="/images/losangeles.jpg" alt="Los Angeles" />
            </div>
            <div className="blog-content">
              <h2>Los Angeles Surf Guide</h2>
              <p>Riding the Waves: Unforgettable Surf Lessons in Los Angeles</p>
              <span className="read-more">Read More →</span>
            </div>
          </div>
        </Link>

        <Link to="/blogs/sandiego" className="blog-link">
          <div className="blog-card">
            <div className="blog-image sandiego-image">
              <img src="/images/sandiego.jpg" alt="San Diego" />
            </div>
            <div className="blog-content">
              <h2>San Diego Surf Guide</h2>
              <p>
                Master the Waves of San Diego: Unleash your inner surfer with
                top-notch surf lessons
              </p>
              <span className="read-more">Read More →</span>
            </div>
          </div>
        </Link>

        <Link to="/blogs/jacksonville" className="blog-link">
          <div className="blog-card">
            <div className="blog-image jacksonville-image">
              <img src="/images/jacksonville.jpg" alt="Jacksonville" />
            </div>
            <div className="blog-content">
              <h2>Jacksonville Surf Guide</h2>
              <p>
                Catch the Waves and Enjoy Unforgettable Surfing Lessons in
                Jacksonville
              </p>
              <span className="read-more">Read More →</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
