import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    topic: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError("");
    setSubmitSuccess(false);

    try {
      const { data, error } = await supabase.from("contact_messages").insert([
        {
          name: formData.name,
          email: formData.email,
          topic: formData.topic,
          message: formData.message,
          created_at: new Date().toISOString(),
        },
      ]);

      if (error) {
        console.error("Detailed error:", {
          code: error.code,
          message: error.message,
          details: error.details,
          hint: error.hint,
        });
        setSubmitError(`Error: ${error.message}`);
        return;
      }

      setSubmitSuccess(true);
      setFormData({
        name: "",
        email: "",
        topic: "",
        message: "",
      });
    } catch (error) {
      console.error("Caught error:", error);
      setSubmitError("Failed to send message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-page">
      <Helmet>
        <title>Contact Get Surfing Lessons - We're Here to Help</title>
        <meta
          name="description"
          content="Get in touch with Get Surfing Lessons. Questions about surf coaching, booking lessons, or becoming a coach? We're here to help you start your surfing journey."
        />
      </Helmet>

      <div className="contact-hero">
        <h1>Get In Touch</h1>
        <p>We're here to help you catch your perfect wave</p>
      </div>

      <div className="contact-content">
        <div className="contact-grid">
          <div className="contact-info">
            <h2>Contact Information</h2>
            <div className="info-cards">
              <div className="info-card">
                <i className="fas fa-envelope"></i>
                <h3>Email Us</h3>
                <p>General Inquiries:</p>
                <a href="mailto:getsurfinglessons@gmail.com">
                  getsurfinglessons@gmail.com
                </a>
              </div>
              <div className="info-card">
                <i className="fas fa-clock"></i>
                <h3>Response Time</h3>
                <p>We typically respond within 24 hours</p>
              </div>
              <div className="info-card">
                <i className="fas fa-map-marker-alt"></i>
                <h3>Locations</h3>
                <p>Available in California, Hawaii, and Florida</p>
              </div>
            </div>
          </div>

          <div className="contact-form-section">
            <h2>Send Us a Message</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="form-group">
                <select
                  name="topic"
                  value={formData.topic}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Topic</option>
                  <option value="booking">Booking Inquiry</option>
                  <option value="coaching">Become a Coach</option>
                  <option value="support">General Support</option>
                </select>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  rows="5"
                  required
                />
              </div>
              <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>

              {submitSuccess && (
                <div className="success-message">
                  Thank you for your message! We'll get back to you soon.
                </div>
              )}

              {submitError && (
                <div className="error-message">{submitError}</div>
              )}
            </form>
          </div>
        </div>

        <div className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-grid">
            <div className="faq-card">
              <h3>How do I book a lesson?</h3>
              <p>
                Browse our coaches, select your preferred instructor, and they
                will contact you within 24 hours.
              </p>
            </div>
            <div className="faq-card">
              <h3>What's included in a lesson?</h3>
              <p>
                Each lesson includes personalized instruction, safety
                guidelines, and basic surfing equipment including wetsuits and
                surfboards.
              </p>
            </div>
            <div className="faq-card">
              <h3>Can I cancel my booking?</h3>
              <p>
                All bookings are subject to instructor availability and weather
                conditions. Each instructor maintains their own cancellation
                policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
