import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Jacksonville() {
  useEffect(() => {
    // Add intersection observer for animation
    const sections = document.querySelectorAll(".content-section");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animation = "fadeIn 0.8s ease forwards";
          }
        });
      },
      { threshold: 0.1 }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="blog-container">
      <div className="hero-section1 jacksonville-img">
        <h1>Surfing in Jacksonville, Florida</h1>
        <div className="hero-overlay1">
          <h2>
            Catch the Waves and Enjoy Unforgettable Surfing Lessons in
            Jacksonville
          </h2>
        </div>
      </div>

      <div className="blog-content">
        <section className="intro-section">
          <p className="lead-paragraph">
            Are you ready to ride the waves and experience the thrill of
            surfing? Look no further than Jacksonville, where our{" "}
            <Link to="/surf-coaches">experienced instructors</Link> offer
            unforgettable surfing lessons. Whether you're a beginner or an
            experienced surfer, Jacksonville offers a range of surf spots and{" "}
            <Link to="/start-your-journey">expert coaches</Link> to help you
            master this exhilarating sport. Check out our{" "}
            <Link to="/blogs/surf-lessons">complete guide to surf lessons</Link>{" "}
            to learn more about getting started. Visit our{" "}
            <Link to="/questions">FAQ section</Link> for answers to common
            questions about surfing in Jacksonville.
          </p>
        </section>

        <section className="content-section">
          <h2>The Best Surfing Spots in Jacksonville</h2>
          <div className="section-content">
            <div className="text-content">
              Jacksonville is home to some of the most incredible surfing spots
              on the East Coast, making it a haven for surfers of all skill
              levels. One of the top locations is Jacksonville Beach, renowned
              for its consistent waves and vibrant surf culture. The beach
              features a long stretch of sand with plenty of room for surfers to
              catch waves, and the proximity to the Jacksonville Beach Pier adds
              an iconic backdrop to your surfing experience. The pier creates a
              unique wave pattern, making it a favorite spot for both beginners
              and seasoned surfers looking to challenge themselves. Another
              fantastic spot is Huguenot Memorial Park, which offers a more
              secluded surfing experience. This area is less crowded than
              Jacksonville Beach, providing a peaceful environment for those who
              want to escape the hustle and bustle of the city. The waves here
              can be quite rewarding, particularly during the summer months when
              the swells are more consistent. Huguenot is also known for its
              natural beauty, with pristine landscapes that enhance the overall
              experience of surfing in a serene setting. For those seeking
              adventure, the area near the Mayport Naval Station features some
              exciting waves that can be perfect for catching larger swells.
              This spot is often frequented by experienced surfers looking to
              push their limits. The combination of natural currents and wind
              conditions at Mayport can create thrilling waves, but it's
              essential to have a good grasp of surfing skills before heading
              out here. Each of these locations showcases the diverse surfing
              opportunities in Jacksonville, catering to a wide range of surfers
              and their preferences.
            </div>
            <div className="section-image">{/* Add relevant image here */}</div>
          </div>
        </section>

        <section className="content-section">
          <h2>Choosing the Right Surfboard and Equipment</h2>
          <div className="section-content">
            <div className="text-content">
              Selecting the right surfboard is crucial for enhancing your
              surfing experience and ensuring your safety in the water. For
              beginners, a longboard is often recommended due to its stability
              and ease of paddling. Longboards are typically wider and provide a
              larger surface area, making it easier for novices to catch waves
              and maintain balance while standing. As you progress and gain
              confidence, you can experiment with shorter boards, such as
              funboards or shortboards, which allow for more maneuverability and
              performance on the waves. In addition to the surfboard, it's
              important to consider other essential gear, including wetsuits,
              rash guards, and leashes. A wetsuit can help regulate your body
              temperature in cooler waters, while a rash guard protects your
              skin from irritation caused by the surfboard or sun exposure. A
              leash is an important safety feature that keeps your board
              attached to you, preventing it from drifting away if you wipe out.
              Ensuring you have the right equipment will enhance your comfort
              and safety while out on the waves. Lastly, consider investing in
              other accessories, such as surf wax and a board bag. Surf wax is
              applied to the deck of your board to provide grip and prevent
              slipping when you are paddling or standing up. A board bag
              protects your surfboard during transport and storage, keeping it
              safe from dings and scratches. By choosing the right surfboard and
              equipment, you are setting yourself up for a successful and
              enjoyable surfing experience in Jacksonville.
            </div>
            <div className="section-image">{/* Add relevant image here */}</div>
          </div>
        </section>

        <section className="content-section">
          <h2>Safety Tips for Surfing in Jacksonville</h2>
          <div className="section-content">
            <div className="text-content">
              Safety should always be a top priority when engaging in any water
              sport, and surfing is no exception. Before heading out,
              familiarize yourself with the local surf conditions and tide
              patterns. Check for any warnings or advisories posted at the
              beach, as conditions can change rapidly. Understanding the
              currents and wave patterns in Jacksonville is essential for making
              informed decisions about where and when to surf. Be mindful of
              your skill level and choose a spot that aligns with your
              experience to minimize risks. It's also important to be aware of
              your surroundings while in the water. Keep an eye out for other
              surfers and swimmers to avoid collisions. Respect the surf
              etiquette, which includes waiting your turn to catch a wave and
              not dropping in on someone else's ride. Communicate with fellow
              surfers and be courteous to create a positive environment in the
              water. If you're new to surfing, consider taking lessons to learn
              these important practices from experienced instructors. Lastly,
              never surf alone, especially if you are a beginner. Having a buddy
              system can significantly enhance your safety while enjoying the
              waves. Ensure that someone is aware of your plans and can assist
              you if needed. Additionally, always wear a life jacket if you are
              not confident in your swimming abilities. By following these
              safety tips, you can enjoy your surfing sessions in Jacksonville
              while minimizing risks and ensuring a fun experience.
            </div>
            <div className="section-image">{/* Add relevant image here */}</div>
          </div>
        </section>

        <section className="content-section">
          <h2>Surfing Lessons for Beginners in Jacksonville</h2>
          <div className="section-content">
            <div className="text-content">
              Ready to start your surfing journey? Our{" "}
              <Link to="/surf-coaches">certified instructors</Link> provide
              comprehensive lessons for all skill levels. Learn more about our
              approach in our{" "}
              <Link to="/blogs/surf-lessons">surfing lessons guide</Link>.
            </div>
            <div className="section-image">{/* Add relevant image here */}</div>
          </div>
        </section>

        <section className="content-section">
          <h2>Intermediate and Advanced Surfing Techniques</h2>
          <div className="section-content">
            <div className="text-content">
              Once you have a solid grasp of the basics, it's time to dive into
              intermediate and advanced surfing techniques that can elevate your
              skills. For intermediate surfers, refining your paddling technique
              is essential. Efficient paddling not only helps you catch waves
              more effectively but also conserves energy, allowing you to stay
              out in the water longer. Focus on maintaining a streamlined
              position on your board, using your arms in a symmetrical motion,
              and engaging your core for better control. As you progress to
              advanced techniques, learning how to perform maneuvers like
              cutbacks, bottom turns, and aerials will enhance your surfing
              repertoire. Cutbacks involve turning back toward the breaking wave
              after riding down the line, allowing you to maintain speed and
              control. Bottom turns are critical for setting up your next move,
              providing momentum as you transition into different sections of
              the wave. Aerials, while more complex, can be a thrilling addition
              to your skill set as you learn to launch off the lip of the wave.
              In addition to technical skills, developing your ability to read
              waves is crucial for advanced surfing. Understanding which waves
              are worth catching and how they will break can significantly
              improve your overall performance. Spending time observing other
              surfers and practicing in various conditions will help you develop
              this skill. The combination of refined techniques and wave
              knowledge will not only boost your confidence but also provide a
              more exhilarating surfing experience in Jacksonville's dynamic
              surf scenes.
            </div>
            <div className="section-image">{/* Add relevant image here */}</div>
          </div>
        </section>

        <section className="content-section">
          <h2>Surfing Events and Competitions in Jacksonville</h2>
          <div className="section-content">
            <div className="text-content">
              Jacksonville's vibrant surf culture is highlighted by various
              surfing events and competitions held throughout the year. These
              events provide opportunities for surfers of all skill levels to
              showcase their talents, connect with fellow enthusiasts, and
              experience the thrill of competition. The annual Jacksonville
              Beach Surfing Championships is one of the most anticipated events,
              attracting participants from across the region. This competition
              typically features various categories, including age divisions and
              skill levels, allowing surfers of all backgrounds to compete. In
              addition to local competitions, Jacksonville is home to several
              surf festivals celebrating the sport and its culture. These
              festivals often feature live music, art displays, and surf-related
              activities, creating a festive atmosphere for participants and
              spectators alike. Events like the Surfing for Autism event raise
              funds for charity while promoting the sport and inspiring new
              surfers to join the community. Attending these festivals is a
              great way to immerse yourself in the local surf scene and meet
              like-minded individuals. For those looking to improve their skills
              and gain valuable experience, participating in competitions is an
              excellent way to challenge yourself. Even if you're not ready to
              compete at a high level, local events often have categories for
              beginners, making it a perfect opportunity to test your skills in
              a friendly environment. Engaging in competitions can foster a
              sense of camaraderie among surfers and motivate you to continue
              improving your abilities on the waves.
            </div>
            <div className="section-image">{/* Add relevant image here */}</div>
          </div>
        </section>

        <section className="content-section">
          <h2>Surfing Communities and Clubs in Jacksonville</h2>
          <div className="section-content">
            <div className="text-content">
              Being part of a surfing community can greatly enhance your
              experience and commitment to the sport. Jacksonville boasts
              numerous surfing clubs and organizations that cater to surfers of
              all ages and skill levels. Joining a local surf club provides a
              sense of belonging, as you connect with fellow surfers who share
              your passion. These communities often organize group surf
              sessions, workshops, and social events, creating opportunities for
              members to learn from one another and foster lasting friendships.
              Many surfing clubs also focus on conservation efforts, promoting
              the protection of local beaches and marine ecosystems.
              Participating in beach cleanups and educational programs allows
              surfers to give back to the environment while enjoying their
              favorite sport. These initiatives not only help preserve the
              natural beauty of Jacksonville's coastline but also create a sense
              of responsibility among surfers to protect the waves they love. In
              addition to clubs, online forums and social media groups can be
              valuable resources for connecting with the local surf community.
              Many surfers share tips, advice, and local surf conditions, making
              it easier for newcomers to navigate the area. Whether you are
              looking for a surf buddy, seeking advice on techniques, or wanting
              to stay updated on local surf events, these communities can
              provide a wealth of information and support to enhance your
              surfing journey.
            </div>
            <div className="section-image">{/* Add relevant image here */}</div>
          </div>
        </section>

        <section className="content-section">
          <h2>Conclusion: Embrace the Thrill of Surfing in Jacksonville</h2>
          <div className="section-content">
            <div className="text-content">
              Ready to catch your first wave in Jacksonville?{" "}
              <Link to="/start-your-journey">Book your lesson today</Link> or{" "}
              <Link to="/contact">contact us</Link> with any questions. Visit
              our <Link to="/about">About page</Link> to learn more about our
              experienced team and commitment to safety.
            </div>
            <div className="section-image">{/* Add relevant image here */}</div>
          </div>
        </section>

        <section className="content-section">
          <h2>Related Articles</h2>
          <ul>
            <li>
              <Link to="/blogs/surf-lessons">
                Complete Guide to Surf Lessons
              </Link>
            </li>
            <li>
              <Link to="/blogs/los-angeles">
                Best Surf Spots in Los Angeles
              </Link>
            </li>
            <li>
              <Link to="/blogs/san-diego">San Diego Surfing Guide</Link>
            </li>
            <li>
              <Link to="/blogs/maui">Surfing in Maui: The Ultimate Guide</Link>
            </li>
            <li>
              <Link to="/blogs/huntington">Huntington Beach Surf Guide</Link>
            </li>
            <li>
              <Link to="/safety">Beach Safety Guidelines</Link>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
