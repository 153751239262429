import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../styles/partitions/privacy.css";

const Privacy = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="privacy-page">
      <div className="privacy-hero">
        <h1>Privacy Policy</h1>
        <p>Last updated: January 2025</p>
      </div>

      <div className="privacy-content">
        <section className="privacy-section">
          <h2>1. Information We Collect</h2>
          <h3>Personal Information</h3>
          <p>When you use GetSurfingLessons.com, we collect:</p>
          <ul>
            <li>Name and contact information</li>
            <li>Email address and phone number</li>
            <li>Location preferences for surf lessons</li>
            <li>Surfing experience level</li>
          </ul>

          <h3>For Surf Coaches</h3>
          <ul>
            <li>Name and contact information</li>
            <li>Email address and phone number</li>
            <li>Professional certifications and qualifications</li>
            <li>Teaching experience and specialties</li>
            <li>Availability and preferred teaching locations</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>2. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Connect students with appropriate surf coaches</li>
            <li>Improve our platform and services</li>
            <li>Ensure safety and verify coach credentials</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>3. Information Sharing</h2>
          <p>We share information only in these circumstances:</p>
          <ul>
            <li>
              Between matched students and coaches for lesson coordination
            </li>
            <li>For legal requirements or safety concerns</li>
            <li>With your explicit consent</li>
          </ul>
          <p>We never sell your personal information to third parties.</p>
        </section>

        <section className="privacy-section">
          <h2>4. Data Security</h2>
          <p>We protect your data through:</p>
          <ul>
            <li>Encrypted data transmission</li>
            <li>Secure payment processing</li>
            <li>Regular security audits</li>
            <li>Limited employee data access</li>
            <li>Secure data storage systems</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>5. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate data</li>
            <li>Request data deletion</li>
            <li>Opt-out of marketing communications</li>
            <li>Export your data</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>6. Cookies and Tracking</h2>
          <p>We use cookies to:</p>
          <ul>
            <li>Improve website functionality</li>
            <li>Remember your preferences</li>
            <li>Analyze site traffic</li>
            <li>Enhance user experience</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>7. Contact Us</h2>
          <p>For privacy-related questions or concerns, contact us at:</p>
          <div className="contact-details">
            <p>
              Email:{" "}
              <a href="mailto:privacy@getsurfinglessons.com">
                getsurfinglessons@gmail.com
              </a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
