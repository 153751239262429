import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const AdminDashboard = () => {
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    const fetchPendingCoaches = async () => {
      try {
        const response = await axios.get(
          "https://findsurfinglessons.onrender.com/api/pendingCoaches"
        );
        setCoaches(response.data);
      } catch (error) {
        console.error("Error fetching pending coaches:", error);
      }
    };
    fetchPendingCoaches();
  }, []);

  const handleApprove = async (coachId) => {
    try {
      await axios.put(
        `https://findsurfinglessons.onrender.com/api/coaches/approve/${coachId}`
      );
      setCoaches((prev) => prev.filter((coach) => coach.id !== coachId));
    } catch (error) {
      console.error("Error approving coach:", error);
    }
  };

  const handleDisapprove = async (coachId) => {
    try {
      await axios.put(
        `https://findsurfinglessons.onrender.com/api/coaches/disapprove/${coachId}`
      );
      setCoaches((prev) => prev.filter((coach) => coach.id !== coachId));
    } catch (error) {
      console.error("Error approving coach:", error);
    }
  };

  return (
    <div>
      <h1 className="title">Admin Dashboard - Pending Coaches</h1>
      <div className="coaches-grid">
          {coaches.map((coach, index) => {
            return (
              <div
                  className="coach-profile-card"
                  key={coach.id}
                >
                  <div className="coach-image-container">
                    <img
                      src={coach.profile_picture}
                      alt={`Coach ${coach.first_name}`}
                      className="coach-image"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../../public/images/surf-2.jpg";
                      }}
                    />
                    <div className="experience-badge">
                      {coach.experience}{" "}
                      {coach.experience === 1 ? "year" : "years"}
                    </div>
                  </div>

                  <div className="coach-info">
                    <h2 className="coach-name">Coach {coach.first_name}</h2>
                    <div className="coach-location">
                      <i className="fas fa-map-marker-alt"></i>
                      {coach.cities}, {coach.state.toUpperCase()}
                    </div>

                    <div className="coach-rates">
                      <div className="rate-item">
                        <span className="rate-label">Private Lessons</span>
                        <span className="rate-value">
                          ${coach.privatelessonrate}/hr
                        </span>
                      </div>
                      <div className="rate-item">
                        <span className="rate-label">Group Lessons</span>
                        <span className="rate-value">
                          ${coach.grouplessonrate}/hr
                        </span>
                      </div>
                    </div>

                    <button onClick={() => handleApprove(coach.id)}>Approve</button>
                    <button onClick={() => handleDisapprove(coach.id)}>Disapprove</button>
                  </div>
                </div>
              );
          })}
      </div>
    </div>
  );
};

export default AdminDashboard;
