import React, { useEffect } from "react";
import "../styles/partitions/safety.css";
import { Link, useLocation } from "react-router-dom";

const Safety = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="safety-page">
      <div className="safety-hero">
        <h1>Safety Guidelines</h1>
        <p>Your safety is our top priority</p>
      </div>

      <div className="safety-content">
        <section className="safety-section">
          <h2>Before Your Lesson</h2>
          <div className="safety-grid">
            <div className="safety-card">
              <h3>Health Requirements</h3>
              <ul>
                <li>Be able to swim confidently in open water</li>
                <li>Disclose any medical conditions to your instructor</li>
                <li>Ensure you're well-rested and hydrated</li>
                <li>Apply reef-safe sunscreen 30 minutes before lesson</li>
              </ul>
            </div>

            <div className="safety-card">
              <h3>Equipment Check</h3>
              <ul>
                <li>Properly fitted wetsuit (if provided)</li>
                <li>Leash attachment and functionality</li>
                <li>Board condition and fin setup</li>
                <li>Rash guard or appropriate sun protection</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="safety-section">
          <h2>Ocean Awareness</h2>
          <div className="safety-grid">
            <div className="safety-card">
              <h3>Understanding Conditions</h3>
              <ul>
                <li>Check local weather and surf reports</li>
                <li>Be aware of tide times and changes</li>
                <li>Understand wind direction and effects</li>
                <li>Recognize safe entry and exit points</li>
              </ul>
            </div>

            <div className="safety-card">
              <h3>Rip Current Safety</h3>
              <ul>
                <li>Learn to identify rip currents</li>
                <li>Don't panic if caught in a rip</li>
                <li>Swim parallel to shore to escape</li>
                <li>Signal for help if needed</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="safety-section">
          <h2>During Your Lesson</h2>
          <div className="safety-guidelines">
            <ul>
              <li>Always follow your instructor's guidance</li>
              <li>Maintain visual contact with your instructor</li>
              <li>Stay within designated teaching areas</li>
              <li>Use proper communication signals</li>
              <li>Respect other surfers and ocean users</li>
              <li>Take regular breaks when needed</li>
            </ul>
          </div>
        </section>

        <section className="safety-section">
          <h2>Emergency Procedures</h2>
          <div className="emergency-info">
            <p>In case of emergency:</p>
            <ul>
              <li>Stay calm and signal your instructor</li>
              <li>Follow established emergency protocols</li>
              <li>Know the location of first aid stations</li>
              <li>Have emergency contact numbers readily available</li>
            </ul>
          </div>
        </section>

        <section className="safety-section">
          <h2>Surf Etiquette</h2>
          <div className="etiquette-rules">
            <ul>
              <li>Right of way rules for wave riding</li>
              <li>Proper paddling protocols</li>
              <li>Respect for local surfers and spots</li>
              <li>Environmental consciousness</li>
            </ul>
          </div>
        </section>

        <div className="safety-disclaimer">
          <h3>Important Notice</h3>
          <p>
            While we ensure all our coaches are certified and follow strict
            safety protocols, surfing involves inherent risks. By participating
            in lessons through our platform, you acknowledge these risks and
            agree to follow all safety guidelines provided by your instructor.
          </p>
          <p>
            For more detailed information about our safety policies, please
            refer to our <Link to="/terms">Terms of Service</Link>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Safety;
