import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DEFAULT_LOCATIONS } from "./data/locations";

const StartYourJourney = () => {
  const [findCity, setFindCity] = useState("");
  const [findState, setFindState] = useState("");
  const [allCities, setAllCities] = useState(DEFAULT_LOCATIONS.cities);
  const [allStates, setAllStates] = useState(DEFAULT_LOCATIONS.states);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!findCity || !findState) {
      alert("Please select both a city and state");
      return;
    }

    navigate(`/start-your-journey/${findCity}/${findState}`, {
      state: {
        city: findCity.trim(),
        state: findState.trim(),
        scrollToFirstCoach: true,
      },
    });
  };
  return (
    <div className="start-your-journey">
        <div className="hero-section">
          <h1 className="hero-title">Begin Your Surfing Adventure</h1>
          <p className="hero-subtitle">
            Connect with professional surf coaches and ride the waves with confidence
          </p>
          <div className="search-container">
            <form onSubmit={handleSubmit} className="search-form">
              <div className="select-wrapper">
                <div className="select-group">
                  <i className="fas fa-map-marker-alt"></i>
                  <select
                    id="city-dropdown"
                    value={findCity}
                    onChange={(e) => setFindCity(e.target.value)}
                    className="search-select"
                  >
                    <option value="">Select Your City</option>
                    {allCities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="select-group">
                  <i className="fas fa-globe-americas"></i>
                  <select
                    id="state-dropdown"
                    value={findState}
                    onChange={(e) => setFindState(e.target.value)}
                    className="search-select"
                  >
                    <option value="">Select Your State</option>
                    {allStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <button type="submit" className="search-button">
                <i className="fas fa-search"></i>
                Find Your Coach
              </button>
            </form>
          </div>
      </div>

      <div className="journey-content">
        <section className="learning-process">
          <h2 className="section-title">Your Journey to Mastery</h2>
          <div className="process-steps">
            <div className="step-card">
              <div className="step-number">1</div>
              <h3>Choose Your Location</h3>
              <p>
                Find certified coaches in your prefexrred surfing destination
              </p>
            </div>
            <div className="step-card">
              <div className="step-number">2</div>
              <h3>Browse Coaches</h3>
              <p>Compare profiles and find your perfect instructor match</p>
            </div>
            <div className="step-card">
              <div className="step-number">3</div>
              <h3>Book Your Lesson</h3>
              <p>Schedule your session and start your surfing journey</p>
            </div>
          </div>
        </section>

        <section className="features-section">
          <h2 className="section-title">Why Choose Us?</h2>
          <div className="features-grid">
            <div className="feature-card">
              <i className="fas fa-certificate"></i>
              <h3>Certified Coaches</h3>
              <p>
                Learn essential surfing techniques from professional instructors
                certified in ocean safety and surf coaching. Our vetted surf
                coaches bring years of experience teaching beginners to advanced
                surfers at top breaks worldwide.
              </p>
            </div>
            <div className="feature-card">
              <i className="fas fa-users"></i>
              <h3>Personalized Learning</h3>
              <p>
                Experience surf lessons tailored to your progression, from
                paddle techniques to wave selection and advanced maneuvers. Your
                surf coach adapts each session to match your learning style and
                surfing goals, ensuring rapid improvement.
              </p>
            </div>
            <div className="feature-card">
              <i className="fas fa-map-marked-alt"></i>
              <h3>Prime Locations</h3>
              <p>
                Discover the best surf spots for your skill level, from gentle
                beach breaks perfect for beginners to challenging reef breaks
                for experienced surfers. Learn local wave conditions, tides, and
                surf etiquette at premier surfing locations.
              </p>
            </div>
            <div className="feature-card">
              <i className="fas fa-shield-alt"></i>
              <h3>Safety First</h3>
              <p>
                Master ocean safety fundamentals, including rip current
                awareness, surf forecasting, and emergency protocols. Get expert
                guidance on proper surfboard selection, wetsuit fitting, and
                essential surf safety equipment for confident wave riding.
              </p>
            </div>
          </div>
        </section>
        <section className="skill-levels">
          <h2 className="section-title">Programs for Every Level</h2>
          <div className="level-cards">
            <div className="level-card beginner">
              <div className="level-header">
                <h3>Beginner</h3>
                <span className="level-tag">Start Here</span>
              </div>
              <ul className="level-features">
                <li>
                  <i className="fas fa-check"></i>Ocean safety fundamentals
                </li>
                <li>
                  <i className="fas fa-check"></i>Proper stance and positioning
                </li>
                <li>
                  <i className="fas fa-check"></i>Wave reading basics
                </li>
                <li>
                  <i className="fas fa-check"></i>Paddling techniques
                </li>
                <li>
                  <i className="fas fa-check"></i>Pop-up practice
                </li>
              </ul>
            </div>

            <div className="level-card intermediate">
              <div className="level-header">
                <h3>Intermediate</h3>
                <span className="level-tag">Level Up</span>
              </div>
              <ul className="level-features">
                <li>
                  <i className="fas fa-check"></i>Advanced wave selection
                </li>
                <li>
                  <i className="fas fa-check"></i>Turn techniques
                </li>
                <li>
                  <i className="fas fa-check"></i>Speed generation
                </li>
                <li>
                  <i className="fas fa-check"></i>Surf etiquette
                </li>
                <li>
                  <i className="fas fa-check"></i>Equipment selection
                </li>
              </ul>
            </div>

            <div className="level-card advanced">
              <div className="level-header">
                <h3>Advanced</h3>
                <span className="level-tag">Master</span>
              </div>
              <ul className="level-features">
                <li>
                  <i className="fas fa-check"></i>Aerial maneuvers
                </li>
                <li>
                  <i className="fas fa-check"></i>Competition strategies
                </li>
                <li>
                  <i className="fas fa-check"></i>Advanced wave riding
                </li>
                <li>
                  <i className="fas fa-check"></i>Performance surfing
                </li>
                <li>
                  <i className="fas fa-check"></i>Big wave techniques
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default StartYourJourney;
