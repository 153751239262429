import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { DEFAULT_LOCATIONS } from "./data/locations";

const SurfCoaches = () => {
  const { findCity, findState } = useParams(); // Retrieves from the URL
  const navigate = useNavigate();
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [findCityTwo, setFindCityTwo] = useState(findCity || "");
  const [findStateTwo, setFindStateTwo] = useState(findState || "");
  const [numberOfCoaches, setNumberOfCoaches] = useState("");
  const [filtering, setFiltering] = useState("");
  const [loading, setLoading] = useState(false);

  const [allCitiesTwo] = useState(DEFAULT_LOCATIONS.cities);
  const [allStatesTwo] = useState(DEFAULT_LOCATIONS.states);

  const firstCoachRef = useRef(null);

  const filterAndSortCoaches = (findCityTwo, findStateTwo, coaches) => {
    const filtered = coaches.filter((coach) => {
      const coachCities = coach.cities?.map((city) => city.toLowerCase()) || [];
      return (
        coach.state.toLowerCase() === findStateTwo.toLowerCase() &&
        coachCities.includes(findCityTwo.toLowerCase())
      );
    });

    const sortedItems = filtered.sort(
      (a, b) => a.privatelessonrate - b.privatelessonrate
    );

    setFilteredCoaches(sortedItems);
    setNumberOfCoaches(
      sortedItems.length === 0
        ? "No Coaches Found. Coming Soon!"
        : sortedItems.length === 1
        ? "1 Coach Found"
        : `${sortedItems.length} Coaches Found`
    );
  };

  // Fetch coaches when component mounts or when a search is submitted
  useEffect(() => {
    const fetchCoaches = async () => {
      setLoading(true);
      try {
        const coachesResponse = await axios.get(
          "https://findsurfinglessons.onrender.com/api/coaches",
          { withCredentials: true }
        );

        if (findCityTwo || findStateTwo) {
          filterAndSortCoaches(findCityTwo, findStateTwo, coachesResponse.data);
        }
      } catch (error) {
        console.error("Error fetching coaches data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCoaches();
  }, [findCityTwo, findStateTwo]); // Only trigger on location change or search submission

  useEffect(() => {
    if (findCityTwo && findStateTwo) {
      navigate(`/start-your-journey/${findCityTwo}/${findStateTwo}`);
    }
  }, [findCityTwo, findStateTwo, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFindCityTwo(findCityTwo);
    setFindStateTwo(findStateTwo);
    navigate(`/start-your-journey/${findCityTwo}/${findStateTwo}`);
  };

  const handleFiltering = (event) => {
    const selected_option = event.target.value;
    setFiltering(event.target.value);
    if (selected_option === "Private Lesson Rate") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => a.privatelessonrate - b.privatelessonrate
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Group Lesson Rate") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => a.grouplessonrate - b.grouplessonrate
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Experience") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => b.experience - a.experience
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Alphabetical") {
      const sortedItems = [...filteredCoaches].sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setFilteredCoaches(sortedItems);
    }
  };

  return (
    <div className="surf-coaches-wrapper">
      <div className="search-header">
        <div className="search-container">
          <h1 className="search-title">Find Your Perfect Surf Coach</h1>
          <h2 className="search-location">
            {findCityTwo && findStateTwo ? (
              <>
                Exploring coaches in {findCityTwo}, {findStateTwo.toUpperCase()}
              </>
            ) : (
              "Select your location to begin"
            )}
          </h2>

          <form onSubmit={handleSubmit} className="location-form">
            <div className="location-selects">
              <div className="surf-coach-select-group">
                <i className="fas fa-map-marker-alt"></i>
                <select
                  id="city-dropdown"
                  value={findCityTwo}
                  onChange={(e) => setFindCityTwo(e.target.value)}
                  className="location-select"
                >
                  <option value="">
                    {allCitiesTwo.length === 0 ? "Loading..." : "Select A City"}
                  </option>
                  {allCitiesTwo.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>

              <div className="surf-coach-select-group">
                <i className="fas fa-globe-americas"></i>
                <select
                  id="state-dropdown"
                  value={findStateTwo}
                  onChange={(e) => setFindStateTwo(e.target.value)}
                  className="location-select"
                >
                  <option value="">
                    {allStatesTwo.length === 0
                      ? "Loading..."
                      : "Select A State"}
                  </option>
                  {allStatesTwo.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="results-section">
        <div className="results-header">
          <div className="results-info">
            <h3 className="results-count">{numberOfCoaches}</h3>
            <select
              value={filtering}
              onChange={handleFiltering}
              className="sort-select"
            >
              <option value="" disabled>
                Sort Coaches By
              </option>
              <option value="Private Lesson Rate">Private Lesson Rate</option>
              <option value="Group Lesson Rate">Group Lesson Rate</option>
              <option value="Experience">Years of Experience</option>
              <option value="Alphabetical">Alphabetical Order</option>
            </select>
          </div>
        </div>

        <div className="coaches-grid">
          {loading ? (
            <div className="loading-state">
              <div className="loading-spinner"></div>
              <p>Discovering surf coaches in your area...</p>
            </div>
          ) : filteredCoaches.length > 0 ? (
            filteredCoaches.map((coach, index) => (
              <div
                className="coach-profile-card"
                key={coach.id}
                ref={index === 0 ? firstCoachRef : null}
              >
                <div className="coach-image-container">
                  <img
                    src={coach.profile_picture}
                    alt={`Coach ${coach.first_name}`}
                    className="coach-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../../public/images/surf-2.jpg";
                    }}
                  />
                  <div className="experience-badge">
                    {coach.experience}
                    {coach.experience === 1
                      ? " Year Experience"
                      : " Years Experience"}
                  </div>
                </div>

                <div className="coach-info">
                  <h2 className="coach-name">Coach {coach.first_name}</h2>
                  <div className="coach-location">
                    <i className="fas fa-map-marker-alt"></i>
                    {findCityTwo}, {findStateTwo.toUpperCase()}
                  </div>

                  <div className="coach-rates">
                    <div className="rate-item">
                      <span className="rate-label">Private Lessons</span>
                      <span className="rate-value">
                        ${coach.privatelessonrate}/hr
                      </span>
                    </div>
                    <div className="rate-item">
                      <span className="rate-label">Group Lessons</span>
                      <span className="rate-value">
                        ${coach.grouplessonrate}/hr
                      </span>
                    </div>
                  </div>

                  <Link
                    to={`/start-your-journey/${findCityTwo}/${findStateTwo}/${coach.id}`}
                    className="view-profile-link"
                  >
                    View Full Profile
                  </Link>
                </div>
              </div>
            ))
          ) : (
            findCityTwo &&
            findStateTwo && (
              <div className="no-results">
                <h3>No coaches found in this area yet</h3>
                <p>Try searching in nearby cities or check back soon!</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SurfCoaches;
