import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function LosAngeles() {
  useEffect(() => {
    const sections = document.querySelectorAll(".content-section");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animation = "fadeIn 0.8s ease forwards";
          }
        });
      },
      { threshold: 0.1 }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="blog-container">
      <div className="hero-section1 losangeles-img">
        <h1>Los Angeles Blog</h1>
        <div className="hero-overlay1">
          <h2>Riding the Waves: Unforgettable Surf Lessons in Los Angeles</h2>
        </div>
      </div>

      <div className="blog-content">
        <section className="intro-section">
          <p className="lead-paragraph">
            Are you ready to catch some waves in the iconic surf destination of
            Los Angeles? Look no further than our platform, where we connect you
            with{" "}
            <Link to="/start-your-journey">experienced local surf coaches</Link>{" "}
            for personalized lessons. Experience the thrill of riding the
            California waves while learning from{" "}
            <Link to="/apply-to-coach">verified instructors</Link> who are
            passionate about sharing their expertise. Through our platform,
            you'll discover the perfect balance between{" "}
            <Link to="/safety">adventure and safety</Link>, as our highly
            skilled coaches guide you through the basics of surfing. Whether
            you're a complete beginner or have some experience under your belt,
            our coaches offer lessons tailored to suit all levels. Immerse
            yourself in the vibrant surf culture of Los Angeles as you learn to
            ride the waves like a pro. Feel the rush of adrenaline as you paddle
            out, catch the perfect wave, and experience the sheer joy of gliding
            across the water. Along the way, enjoy breathtaking views of the
            stunning California coastline. Don't miss this opportunity to create
            unforgettable memories and improve your surfing skills. Book your
            private lesson with one of our verified coaches today and get ready
            to ride the waves like a true Californian.
          </p>
        </section>
        <section className="content-section">
          <h2>The best surf spots in Los Angeles</h2>
          <div className="section-content">
            <div className="text-content">
              Los Angeles is renowned for its stunning beaches and vibrant surf
              culture, making it a premier destination for surfers of all skill
              levels. From the world-famous waves of Malibu to the laid-back
              vibes of Venice Beach, the city offers a diverse range of surf
              spots that cater to different preferences and abilities. Malibu's
              First Point is often hailed as one of the best surfing locations,
              featuring long, peeling waves that are perfect for longboarders.
              The picturesque setting, combined with consistent surf, makes it a
              must-visit for anyone looking to ride the waves in L.A. Another
              iconic spot is Venice Beach, known for its eclectic atmosphere and
              welcoming surf community. Here, you can find waves that are
              suitable for beginners and intermediates alike, making it an ideal
              location for those taking their first lesson. The beach is often
              bustling with activity, from street performers to beach volleyball
              games, creating an exciting environment for surfers. Plus, the
              nearby boardwalk offers plenty of opportunities to relax and soak
              in the sun after an exhilarating surf session. For those seeking a
              more challenging experience, El Porto in Manhattan Beach is a
              popular choice among seasoned surfers. The beach is known for its
              powerful waves and consistent swells, making it a favorite among
              locals. With a more rugged surf scene, El Porto can be a great
              place to hone your skills and improve your technique.
              Additionally, the area boasts a strong surf community, ensuring
              that you'll always find fellow surfers ready to share tips and
              stories about their rides.
              <section className="content-section">
                <h2>Choosing the right surf coach for you</h2>
                <div className="section-content">
                  <div className="text-content">
                    When it comes to choosing the right surf coach, it's
                    essential to consider your skill level and personal goals.
                    Our platform makes it easy to find the perfect match by
                    browsing coach profiles, reading reviews, and selecting
                    someone who aligns with your needs. If you're a complete
                    beginner, look for coaches who specialize in teaching
                    fundamentals like paddling, balancing, and catching waves.
                    Many of our verified coaches offer introductory lessons
                    designed specifically for those with little to no
                    experience. These sessions typically cover essential
                    techniques and safety protocols, ensuring that you're
                    well-equipped to hit the waves confidently. For those who
                    have some prior experience, you might want to consider
                    coaches who specialize in intermediate or advanced
                    instruction. These sessions often focus on refining your
                    technique, improving your pop-up, and learning how to read
                    the waves. Some coaches even offer specialized training that
                    concentrates on specific skills, such as maneuvering or
                    turning, which can help elevate your surfing game.
                    Consulting with your chosen coach about your experience
                    level will help them tailor the lesson to your needs.
                    Additionally, consider the type of learning environment you
                    prefer. While some coaches focus on one-on-one instruction
                    for maximum personal attention, others offer small group
                    sessions that can be a fun way to meet fellow surfers and
                    enjoy a social atmosphere. Whichever option you choose, rest
                    assured that all our coaches are verified and have a solid
                    reputation for teaching, as this will enhance your overall
                    learning experience.
                  </div>
                </div>
              </section>
              <section className="content-section">
                <h2>What to expect during a surf lesson</h2>
                <div className="section-content">
                  <div className="text-content">
                    Your private surf lesson typically begins with a brief
                    introduction to the fundamentals of surfing, including
                    safety guidelines and an overview of the equipment. Your
                    coach will explain essential techniques such as paddling,
                    positioning on the board, and the proper way to pop up when
                    catching a wave. This initial instruction is crucial for
                    building a solid foundation and ensuring that you're
                    well-prepared for your time in the water. Expect to engage
                    in both theoretical learning and practical exercises during
                    this phase. Once you've learned the basics on land, it's
                    time to hit the water. Your coach will guide you to the best
                    spot based on the day's conditions, ensuring that you're
                    surfing in an area suitable for your skill level. As you
                    paddle out, you'll receive real-time feedback and
                    assistance, helping you navigate the waves and position
                    yourself correctly. This hands-on experience is vital for
                    building confidence and understanding how to interact with
                    the ocean. Throughout the lesson, you can expect plenty of
                    encouragement and support from your coach and fellow
                    surfers. Surfing is as much about camaraderie as it is about
                    individual skill, and the surf community in Los Angeles is
                    known for being welcoming and friendly. As you catch your
                    first waves, you'll feel a rush of adrenaline and a sense of
                    accomplishment that makes the entire experience
                    unforgettable. By the end of the lesson, you'll likely leave
                    the water with newfound skills and a desire to continue
                    surfing.
                  </div>
                </div>
              </section>
              <section className="content-section">
                <h2>Essential equipment for surfing in Los Angeles</h2>
                <div className="section-content">
                  <div className="text-content">
                    To get started with surfing, having the right equipment is
                    essential. The most critical piece of gear is, of course,
                    the surfboard. Beginners often benefit from using a soft-top
                    board, which is more forgiving and provides greater
                    stability on the water. These boards are designed to
                    minimize the risk of injury and are easier to balance on,
                    making them ideal for those new to the sport. As you
                    progress, you may opt for a hard-top board that suits your
                    style and preferences. In addition to the surfboard, a good
                    wetsuit is crucial for comfort while surfing in Los Angeles.
                    The water temperature can vary throughout the year, and a
                    wetsuit helps keep you warm and protected from the elements.
                    Typically, a 3/2mm wetsuit is suitable for most of the year,
                    but during colder months, a thicker suit may be necessary.
                    Wetsuits also provide an extra layer of protection against
                    potential scrapes and stings from marine life. Lastly, don't
                    forget about accessories such as a leash and wax. The leash
                    is an important safety feature that attaches your board to
                    your ankle, preventing it from drifting away when you wipe
                    out. Wax is used to increase traction on your board,
                    ensuring you don't slip off while paddling or riding a wave.
                    Most surf schools provide the necessary equipment for
                    beginners, but it's also helpful to familiarize yourself
                    with these essentials as you develop your surfing skills.
                  </div>
                </div>
              </section>
              <section className="content-section">
                <h2>Safety tips for beginners</h2>
                <div className="section-content">
                  <div className="text-content">
                    Safety should always be a top priority when surfing,
                    especially for beginners. One of the most important tips is
                    to always be aware of your surroundings. Before entering the
                    water, take a moment to observe the conditions, including
                    wave size, currents, and the presence of other surfers.
                    Understanding these factors will help you make informed
                    decisions about where and when to surf. Additionally, it's
                    vital to know your limits; if the waves look too challenging
                    or you're feeling fatigued, it's perfectly acceptable to sit
                    out for a while. Another crucial safety tip is to practice
                    proper surf etiquette. This means respecting the lineup and
                    waiting your turn to catch waves. Dropping in on someone
                    else's wave can lead to dangerous collisions, so be sure to
                    communicate with other surfers and follow the unwritten
                    rules of the ocean. Learning these etiquette norms will not
                    only keep you safe but will also help you integrate into the
                    surf community. Lastly, always wear your leash and ensure
                    it's securely attached to both your ankle and the board.
                    This simple precaution can prevent your board from becoming
                    a hazard to other surfers if you wipe out. Additionally,
                    consider taking a surf lesson with a certified instructor
                    who can provide guidance on safety practices specific to the
                    location where you'll be surfing. By prioritizing safety,
                    you'll enjoy your surfing experience while minimizing the
                    risk of accidents.
                  </div>
                </div>
              </section>
              <section className="content-section">
                <h2>Benefits of learning to surf in Los Angeles</h2>
                <div className="section-content">
                  <div className="text-content">
                    Learning to surf in Los Angeles offers numerous benefits
                    beyond just improving your skills on the water. First and
                    foremost, surfing is a fantastic way to stay active and
                    improve your physical fitness. Paddling out, balancing on
                    the board, and maneuvering through waves engage multiple
                    muscle groups, providing a full-body workout. It's an
                    enjoyable way to stay fit, as the ocean serves as your gym,
                    and the stunning coastal scenery adds to the experience. In
                    addition to the physical benefits, surfing can significantly
                    enhance your mental well-being. The ocean has a calming
                    effect, and spending time in nature has been shown to reduce
                    stress and anxiety. Riding the waves provides an
                    exhilarating rush that boosts your mood and promotes
                    feelings of happiness. As you learn to surf, you'll also
                    develop a sense of accomplishment and confidence, which can
                    positively impact other areas of your life. Furthermore,
                    surfing in Los Angeles allows you to connect with a vibrant
                    community of like-minded individuals. The surf culture here
                    is rich and diverse, providing opportunities to meet new
                    friends and share experiences. Whether you're participating
                    in group lessons or joining local surf events, you'll find a
                    welcoming environment filled with camaraderie and support.
                    This sense of community can enhance your learning experience
                    and create lasting memories as you ride the waves together.
                  </div>
                </div>
              </section>
              <section className="content-section">
                <h2>Surfing etiquette and rules in Los Angeles</h2>
                <div className="section-content">
                  <div className="text-content">
                    Understanding surfing etiquette is crucial for anyone
                    looking to enjoy the waves in Los Angeles. The first rule of
                    thumb is to respect the lineup, which refers to the order in
                    which surfers catch waves. The surfer closest to the peak of
                    the wave has the right of way. If you are further down the
                    line, it's essential to wait your turn and not drop in on
                    someone else's wave. This practice helps maintain harmony in
                    the water and prevents collisions. Another important aspect
                    of surfing etiquette is to be aware of your surroundings and
                    communicate with fellow surfers. If you see someone paddling
                    for a wave, be considerate and allow them space.
                    Additionally, if you're paddling out and someone is about to
                    catch a wave, it's good practice to call out "coming down"
                    or "going" to alert them of your position. Clear
                    communication fosters a positive atmosphere and reduces the
                    likelihood of accidents. Lastly, always be respectful of the
                    ocean and the environment. Avoid littering on the beach and
                    take care not to damage marine life or coral reefs. Many
                    surf spots in Los Angeles have dedicated surf communities
                    that advocate for ocean conservation, and participating in
                    these efforts further enhances your connection to the
                    surfing community. By adhering to these etiquette practices,
                    you'll not only ensure your safety but also contribute to a
                    positive surfing environment for everyone.
                  </div>
                </div>
              </section>
              <section className="content-section">
                <h2>Surfing competitions and events in Los Angeles</h2>
                <div className="section-content">
                  <div className="text-content">
                    Los Angeles hosts a variety of surfing competitions and
                    events throughout the year, showcasing the talent and
                    passion of surfers from all over the world. One of the most
                    notable events is the Vans US Open of Surfing, held annually
                    in Huntington Beach. This prestigious competition attracts
                    top surfers and spectators alike, offering thrilling heats
                    and an electric atmosphere. The event also features music,
                    art, and other activities, making it a celebration of surf
                    culture. In addition to major competitions, many local surf
                    schools and organizations host smaller events and contests,
                    providing opportunities for surfers of all skill levels to
                    showcase their abilities. These events often include
                    categories for beginners and intermediates, fostering a
                    supportive environment for those just starting their surfing
                    journey. Participating in local competitions can be a great
                    way to meet fellow surfers, gain experience, and challenge
                    yourself in a friendly setting. Moreover, many surf schools
                    in Los Angeles organize surf camps and workshops that often
                    culminate in friendly competitions. These events not only
                    enhance your surfing skills but also allow you to experience
                    the thrill of competing while surrounded by a supportive
                    community. Whether you're watching or participating, the
                    surf competitions in Los Angeles offer an unforgettable
                    experience that embodies the spirit of the surf culture.
                  </div>
                </div>
              </section>
              <section className="content-section">
                <h2>Conclusion and final thoughts</h2>
                <div className="section-content">
                  <div className="text-content">
                    Riding the waves in Los Angeles is an adventure like no
                    other, offering a unique blend of excitement, community, and
                    personal growth. By taking surf lessons, you'll not only
                    develop valuable skills but also immerse yourself in the
                    vibrant culture that surrounds this iconic sport. From the
                    breathtaking beaches to the exhilarating feeling of catching
                    your first wave, every moment spent surfing will leave you
                    with lasting memories. As you embark on your surfing
                    journey, remember to prioritize safety and respect the
                    ocean. Embrace the learning process, and don't hesitate to
                    ask for help or guidance from more experienced surfers. The
                    surf community in Los Angeles is incredibly welcoming, and
                    you'll find plenty of individuals willing to share their
                    knowledge and passion for the sport. In conclusion, whether
                    you're a seasoned pro or a complete newbie, Los Angeles
                    offers something for everyone. So grab your board, book your
                    lesson, and get ready to experience the thrill of riding the
                    waves in one of the most beautiful surfing destinations in
                    the world. The ocean is calling, and the waves are waiting
                    for you to join the ride.
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
