import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Questions() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <h1 className="questions-heading">Frequently Asked Questions</h1>

      <div className="questions-container">
        <div className="question-answer">
          <h2>How long does it take to learn surfing?</h2>
          <p>
            Most beginners can stand up and ride their first wave within 1-2
            lessons. However, mastering surfing basics typically takes 3-4
            months of consistent practice. Your progression depends on factors
            like: <br></br>
            <br></br>- Physical fitness and balance <br></br>- Wave conditions
            and frequency of practice <br></br>- Quality of instruction{" "}
            <br></br>- Previous board sports experience <br></br>
            <br></br>With professional instruction from our certified coaches,
            you'll learn proper technique from day one, accelerating your
            progress.
          </p>
        </div>

        <div className="question-answer">
          <h2>Can you teach yourself to surf?</h2>
          <p>
            While self-teaching is possible, taking professional surf lessons is
            strongly recommended for safety and proper skill development.
            Professional instructors provide: <br></br>
            <br></br>- Essential ocean safety knowledge <br></br>- Proper
            technique from the start <br></br>- Wave selection guidance{" "}
            <br></br>- Equipment recommendations <br></br>- Faster learning
            progression <br></br>
            <br></br>Self-teaching often leads to bad habits that are difficult
            to correct later and can put you at risk in the ocean.
          </p>
        </div>

        <div className="question-answer">
          <h2>How much do surfing lessons cost?</h2>
          <p>
            Our surf lessons start at $75 for private one-on-one instruction.
            Lesson costs vary based on: <br></br>
            <br></br>- Private vs. group lessons <br></br>- Lesson duration
            (typically 1-2 hours) <br></br>- Location (California, Hawaii, or
            Florida) <br></br>- Instructor experience level <br></br>
            <br></br>Group lessons can be more affordable, while private lessons
            offer personalized attention. All lessons include board and wetsuit
            rental when needed.
          </p>
        </div>
        <div className="question-answer">
          <h2>What do i need to bring to my surfing lesson?</h2>
          <p>
            We recommend bringing water, sunscreen, and a towel. Also a bach
            bag to carry your belongings. A wetsuit (if needed based on water
            temperature) and surfboard will be provided.
          </p>
        </div>
        <div className="question-answer">
          <h2>How many surfing lessons do I need?</h2>
          <p>
            We recommend starting with 3-5 lessons to build a solid foundation.
            This typically includes: <br></br>
            <br></br>- 1-2 lessons for basics and ocean safety <br></br>- 2-3
            lessons for wave riding techniques <br></br>- Additional lessons
            based on your goals <br></br>
            <br></br>Many students continue with periodic lessons to improve
            specific skills or tackle more challenging conditions. Your
            instructor will help create a personalized learning plan.
          </p>
        </div>

        <div className="question-answer">
          <h2>Are surf lessons worth it?</h2>
          <p>
            Yes, surf lessons are absolutely worth the investment for several
            reasons: <br></br>
            <br></br>- Learn proper technique from the start <br></br>-
            Understand ocean safety and etiquette <br></br>- Access to quality
            equipment and safe learning environments <br></br>- Faster
            progression with expert guidance <br></br>- Build confidence with
            experienced support <br></br>
            <br></br>Professional instruction helps you avoid common beginner
            mistakes and creates a strong foundation for lifelong surfing
            enjoyment.
          </p>
        </div>

        <div className="question-answer">
          <h2>Why surfing is dangerous?</h2>
          <p>
            Surfing carries inherent risks that can be effectively managed with
            proper instruction and safety knowledge:<br></br> <br></br>- Ocean
            conditions (rip currents, waves, rocks) <br></br>- Equipment-related
            injuries
            <br></br>- Collisions with other surfers <br></br>- Marine life
            encounters <br></br>- Physical exhaustion <br></br>
            <br></br>This is why we emphasize safety first and recommend lessons
            with our certified instructors who teach ocean awareness and safe
            surfing practices.
          </p>
        </div>
      </div>
    </div>
  );
}
