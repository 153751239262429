import React, { useState, useEffect, useRef } from "react";
import { createClient } from "@supabase/supabase-js";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default function ApplyToCoach() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cities, setCities] = useState("");
  const [experience, setExperience] = useState("");
  const [privateLessonRate, setPrivateLessonRate] = useState("");
  const [groupLessonRate, setGroupLessonRate] = useState("");
  const [state, setStateName] = useState("");
  const [profileAbout, setProfileAbout] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warning, setWarning] = useState("");
  const formRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(""); // State to track background image

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const img = new Image();
    img.src = "../../public/images/new-6.jpg";
    img.onload = () => {
      setBackgroundImage(`url(${img.src})`); // Set the background image once it's loaded
    };
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setWarning("Please select a file.");
      return;
    }

    // Check file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      setWarning("File size should not exceed 5MB");
      return;
    }

    // Check file type
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/heic",
      "image/heif",
    ];
    if (!validTypes.includes(file.type.toLowerCase())) {
      setWarning("Only image files (JPEG, PNG, GIF, HEIC) are allowed.");
      return;
    }

    setProfilePicture(file);
    setWarning(""); // Clear any previous warnings
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      setSuccessMessage("");
      setIsModalOpen(false);

      let imageUrl = null;
      if (profilePicture) {
        try {
          const { data, error } = await supabase.storage
            .from("profile-images")
            .upload(
              `public/${Date.now()}-${profilePicture.name}`,
              profilePicture,
              {
                cacheControl: "3600",
                upsert: false,
              }
            );

          if (error) {
            console.error("Error uploading image:", error);
            throw new Error("Error uploading image. Please try again.");
          }

          const { data: urlData } = supabase.storage
            .from("profile-images")
            .getPublicUrl(data.path);

          imageUrl = urlData.publicUrl;
        } catch (imageError) {
          console.error("Image upload error:", imageError);
          // Continue with form submission even if image upload fails
          console.log("Continuing with form submission despite image error");
        }
      }

      // Create FormData object
      const formData = new FormData();
      formData.append("firstName", firstName.trim());
      formData.append("lastName", lastName.trim());
      formData.append("email", email.trim());
      formData.append("phoneNumber", phoneNumber.trim());
      formData.append("state", state.trim().toUpperCase());
      formData.append("experience", experience.trim());
      formData.append(
        "privateLessonRate",
        privateLessonRate.trim().replace("$", "")
      );
      formData.append(
        "groupLessonRate",
        groupLessonRate.trim().replace("$", "")
      );
      formData.append("profileAbout", profileAbout.trim());
      formData.append("profilePictureLink", imageUrl || "");

      // Handle cities properly
      const citiesArray = cities.split(",").map((city) => city.trim());
      formData.append("cities", citiesArray.join(","));

      try {
        await fetch("https://findsurfinglessons.onrender.com/api/coaches", {
          method: "POST",
          body: formData,
        });

        // Reset form fields
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setCities("");
        setStateName("");
        setExperience("");
        setPrivateLessonRate("");
        setGroupLessonRate("");
        setProfileAbout("");
        setProfilePicture(null);

        // Reset the form element itself
        if (formRef.current) {
          formRef.current.reset();
        }

        // Show success message
        setSuccessMessage(
          `Thank you for applying to become a coach! Your application is under review, and we will notify you once your coach profile is live. This process typically takes up to 24 hours.`
        );
        setIsModalOpen(true);
      } catch (fetchError) {
        console.error("Fetch error:", fetchError);
        // Still show success if the error is likely just a timeout
        setSuccessMessage(
          `Thank you for applying to become a coach! Your application is under review, and we will notify you once your coach profile is live. This process typically takes up to 24 hours.`
        );
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Submission error:", error);
      setWarning("Please check your connection and try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSuccessMessage("");
  };

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="apply-coach-container">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Submitting... Please wait.</p>
        </div>
      )}

      {/* Hero Section */}
      <section className="apply-coach-hero">
        <div className="hero-content-2">
          <div className="hero-text">
            <h1>Share Your Passion</h1>
            <h2>Become a Surf Coach</h2>
            <p>
              Join our community of expert instructors and inspire the next
              generation of surfers
            </p>
            <button onClick={scrollToForm} className="cta-button">
              Start Your Journey
            </button>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <h2>Why Choose Us?</h2>
        <div className="benefits-grid">
          <div className="benefit-card">
            <i className="fas fa-money-bill-wave"></i>
            <h3>Keep 100% Earnings</h3>
            <p>
              Earn what you deserve with zero platform fees. It's completely
              free to post and maintain your coaching profile!
            </p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-clock"></i>
            <h3>Flexible Schedule</h3>
            <p>
              Work when and where you want. Set your own hours and choose which
              lessons to accept based on your availability.
            </p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-users"></i>
            <h3>Growing Community</h3>
            <p>
              Join our thriving community of surf enthusiasts. Connect with
              students who are eager to learn and share your passion for
              surfing.
            </p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-star"></i>
            <h3>Build Your Brand</h3>
            <p>
              Establish your reputation as an expert. Create a professional
              profile, showcase your experience, and grow your client base.
            </p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-globe"></i>
            <h3>Nationwide Reach</h3>
            <p>
              Connect with students from across the country. Teach at your
              favorite spots and expand your teaching locations.
            </p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-shield-alt"></i>
            <h3>Full Control</h3>
            <p>
              Set your own rates for private and group lessons. You're in
              complete control of your coaching business.
            </p>
          </div>
        </div>
        <div className="benefits-footer">
          <p>
            Join our platform today and become part of the fastest-growing surf
            coaching community in the United States!
          </p>
          <p className="highlight-text">
            No subscription fees • No commission fees • No hidden charges
          </p>
        </div>
      </section>

      {/* Application Form */}
      <section className="application-section" ref={formRef}>
        <div className="form-container">
          <h2>Apply to Become a Coach</h2>
          <form onSubmit={handleSubmit} className="application-form">
            <div className="form-grid">
              <div className="form-group">
                <label>Personal Information</label>
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>Location & Experience</label>
                <input
                  type="text"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setStateName(e.target.value)}
                  required
                />
                <textarea
                  placeholder="Cities you teach in (comma-separated)"
                  value={cities}
                  onChange={(e) => setCities(e.target.value)}
                  required
                />
                <input
                  type="number"
                  placeholder="Years of Experience"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>Pricing</label>
                <div className="price-inputs">
                  <input
                    type="number"
                    placeholder="Private Lesson Rate ($)"
                    value={privateLessonRate}
                    onChange={(e) => setPrivateLessonRate(e.target.value)}
                    required
                  />
                  <input
                    type="number"
                    placeholder="Group Lesson Rate ($)"
                    value={groupLessonRate}
                    onChange={(e) => setGroupLessonRate(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-group full-width">
                <label>About You</label>
                <textarea
                  placeholder="Tell us about your experience, teaching philosophy, and what makes you unique..."
                  value={profileAbout}
                  onChange={(e) => setProfileAbout(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>Profile Picture</label>
                <div className="file-upload">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    required
                  />
                  <i className="fas fa-cloud-upload-alt"></i>
                  <span>Choose a file</span>
                </div>
              </div>
            </div>

            {warning && <div className="warning-message">{warning}</div>}

            <div className="terms-section">
              <label className="checkbox-label">
                <input type="checkbox" required />
                <span>
                  I agree to the <Link to="/terms">Terms of Service</Link>,{" "}
                  <Link to="/privacy">Privacy Policy</Link>, and{" "}
                  <Link to="/safety">Safety Guidelines</Link>
                </span>
              </label>
            </div>

            <button type="submit" className="submit-button">
              Submit Application
            </button>
          </form>
        </div>
      </section>

      {/* Success Modal */}
      {isModalOpen && (
        <div className="apply-success-overlay">
          <div className="apply-success-modal">
            <div className="apply-success-content">
              <div className="apply-success-icon">
                <FaCheckCircle />
              </div>
              <h2 className="apply-success-title">Application Submitted!</h2>
              <p className="apply-success-message">{successMessage}</p>
              <button onClick={closeModal} className="apply-success-btn">
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
