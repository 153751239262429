import React from "react";
import { Outlet } from "react-router-dom"; // Outlet is used for nested routes
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="layout">
      <main className="content">
        <Outlet /> {/* This will render the child routes */}
      </main>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Get Surfing Lessons</h3>
            <p>
              Your journey to becoming a surfer starts here. Professional
              instruction, amazing locations, unforgettable experiences.
            </p>
            <div className="contact-info">
              <p>
                Email:
                <a href="mailto:getsurfinglessons@gmail.com">
                  getsurfinglessons@gmail.com
                </a>
              </p>
            </div>
          </div>

          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/start-your-journey">Find a Coach</Link>
              </li>
              <li>
                <Link to="/apply-to-coach">Become a Coach</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h3>Resources</h3>
            <ul>
              <li>
                <Link to="/blogs">Surf Blog</Link>
              </li>
              <li>
                <Link to="/questions">FAQs</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Service</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/safety">Safety Guidelines</Link>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h3>Connect With Us</h3>
            <div className="social-links">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 GetSurfingLessons.com | All rights reserved</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
