import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page">
      <Helmet>
        <title>
          About Get Surfing Lessons - Your Journey to Surfing Excellence
        </title>
        <meta
          name="description"
          content="Learn about Get Surfing Lessons, connecting passionate surf coaches with aspiring surfers. Discover our mission, values, and commitment to the surfing community."
        />
      </Helmet>

      <div className="about-hero">
        <h1>About Get Surfing Lessons</h1>
        <p>Connecting Passionate Surfers Since 2023</p>
      </div>

      <section className="about-section">
        <div className="content-wrapper">
          <div className="text-content">
            <h2>Our Story</h2>
            <p>
              Born from a deep love for the ocean and surfing, Get Surfing
              Lessons started with a simple mission: making quality surf
              instruction accessible to everyone. What began as a small network
              of local surf coaches has grown into a thriving community of
              passionate instructors and eager learners.
            </p>
          </div>
          <div className="image-wrapper">
            <img
              src="/images/surf-4.jpg"
              alt="Surfer at sunset"
              className="about-image"
            />
          </div>
        </div>
      </section>

      <section className="about-section values-section">
        <h2>Our Core Values</h2>
        <div className="values-grid">
          <div className="value-card">
            <i className="fas fa-heart"></i>
            <h3>Passion</h3>
            <p>
              We're driven by our deep passion for surf culture, wave riding,
              and protecting our ocean playgrounds. Our commitment to
              sustainable surfing practices and marine conservation ensures
              future generations can enjoy the waves.
            </p>
          </div>
          <div className="value-card">
            <i className="fas fa-users"></i>
            <h3>Community</h3>
            <p>
              Join our thriving surf community where beginners and experienced
              surfers connect, share wave knowledge, and embrace surf culture.
              Experience the unique camaraderie of dawn patrols and post-surf
              sessions.
            </p>
          </div>
          <div className="value-card">
            <i className="fas fa-star"></i>
            <h3>Excellence</h3>
            <p>
              Experience world-class surf coaching from certified instructors
              who excel in teaching surf techniques, wave reading, and ocean
              awareness. Our proven teaching methods ensure rapid progression
              from foam boards to green waves.
            </p>
          </div>
          <div className="value-card">
            <i className="fas fa-shield-alt"></i>
            <h3>Safety</h3>
            <p>
              Your safety in the surf is our top priority. Learn essential ocean
              safety skills, surf spot assessment, and emergency protocols from
              experienced watermen who understand local break conditions and
              surf dynamics.
            </p>
          </div>
        </div>
      </section>

      <section className="about-section impact-section">
        <div className="content-wrapper reverse">
          <div className="text-content">
            <h2>Our Impact</h2>
            <div className="impact-stats">
              <div className="stat">
                <h3>100+</h3>
                <p>Successful Lessons</p>
              </div>
              <div className="stat">
                <h3>10+</h3>
                <p>Certified Coaches</p>
              </div>
              <div className="stat">
                <h3>20+</h3>
                <p>Locations</p>
              </div>
            </div>
          </div>
          <div className="image-wrapper">
            <img
              src="/images/surf-5.jpg"
              alt="Group surf lesson"
              className="about-image"
            />
          </div>
        </div>
      </section>

      <section className="about-section commitment-section">
        <h2>Our Commitment to You</h2>
        <div className="commitment-grid">
          <div className="commitment-card">
            <h3>Quality Instruction</h3>
            <p>
              Every coach is vetted and certified to ensure high-quality lessons
            </p>
          </div>
          <div className="commitment-card">
            <h3>Flexible Scheduling</h3>
            <p>Book lessons that fit your schedule, any day of the week</p>
          </div>
          <div className="commitment-card">
            <h3>Perfect Match</h3>
            <p>
              We'll help you find the right coach for your skill level and goals
            </p>
          </div>
        </div>
      </section>
      <section className="about-section cta-section">
        <h2>Ready to Start Your Surfing Journey?</h2>
        <p>
          Join our community of surfers and experience the thrill of riding the
          waves
        </p>
        <div className="cta-buttons">
          <Link to="/start-your-journey">
            <button className="cta-button primary">Find Your Coach</button>
          </Link>
          <Link to="/apply-to-coach">
            <button className="cta-button secondary">Become a Coach</button>
          </Link>
        </div>
      </section>
    </div>
  );
}
