import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import StartYourJourney from "./components/StartYourJourney";
import ApplyToCoach from "./components/ApplyToCoach";
import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import Profile from "./components/Profile";
import SurfCoaches from "./components/SurfCoaches";
import Footer from "./components/Footer";
import AdminDashboard from "./components/admin/AdminDashboard";
import Blogs from "./components/blogs/Blogs";
import Maui from "./components/blogs/Maui";
import Huntington from "./components/blogs/Huntington";
import Questions from "./components/Questions";
import SurfLessons from "./components/blogs/SurfLessons";
import LosAngeles from "./components/blogs/Losangeles";
import SanDiego from "./components/blogs/SanDiego";
import { HelmetProvider } from "react-helmet-async";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Safety from "./components/Safety";
import Jacksonville from "./components/blogs/Jacksonville";
import "@fortawesome/fontawesome-free/css/all.min.css";
function Layout() {
  return (
    <HelmetProvider>
      <div className="layout">
        <Header />
        <main className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/start-your-journey" element={<StartYourJourney />} />
            <Route path="/apply-to-coach" element={<ApplyToCoach />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/maui" element={<Maui />} />
            <Route path="/blogs/huntington" element={<Huntington />} />
            <Route path="/blogs/surf-lessons" element={<SurfLessons />} />
            <Route path="/blogs/losangeles" element={<LosAngeles />} />
            <Route path="/blogs/sandiego" element={<SanDiego />} />
            <Route path="/blogs/jacksonville" element={<Jacksonville />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/questions" element={<Questions />} />
            <Route path="/adminpage" element={<AdminDashboard />} />
            <Route
              path="/start-your-journey/:findCity/:findState"
              element={<SurfCoaches />}
            />
            <Route
              path="/start-your-journey/:findCity/:findState/:id"
              element={<Profile />}
            />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/safety" element={<Safety />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
