import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../styles/partitions/terms.css";

const Terms = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="terms-page">
      <div className="terms-hero">
        <h1>Terms of Service</h1>
        <p>Last updated: January 2025</p>
      </div>

      <div className="terms-content">
        <section className="terms-section">
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using GetSurfingLessons.com ("the Website"), you
            accept and agree to be bound by these Terms of Service. If you do
            not agree to these terms, please do not use our services.
          </p>
        </section>

        <section className="terms-section">
          <h2>2. Services Description</h2>
          <p>
            GetSurfingLessons.com is a platform connecting surf instructors with
            students seeking surfing lessons. We facilitate the booking process
            but are not directly responsible for the provision of surfing
            instruction.
          </p>
          <ul>
            <li>We verify instructor credentials and certifications</li>
            <li>We provide a booking platform for lessons</li>
            <li>
              We facilitate communication between instructors and students
            </li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>3. User Responsibilities</h2>
          <p>Users of our platform agree to:</p>
          <ul>
            <li>Provide accurate and current information</li>
            <li>Maintain the confidentiality of their account</li>
            <li>Not transfer or share account access</li>
            <li>Comply with all local laws and regulations</li>
            <li>Behave respectfully towards instructors and other users</li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>4. Booking and Cancellation</h2>
          <p>
            All bookings are subject to instructor availability and weather
            conditions. Each instructor maintains their own cancellation policy.
          </p>
        </section>

        <section className="terms-section">
          <h2>5. Safety, Liability, and Disclaimers</h2>
          <p>
            GetSurfingLessons.com operates solely as a platform connecting surf
            instructors with potential students. Users must understand and
            acknowledge the following:
          </p>
          <div className="liability-points">
            <h3>Platform Role</h3>
            <ul>
              <li>
                We are strictly a connecting platform between students and
                independent surf instructors
              </li>
              <li>
                We do not employ, manage, or directly oversee any surf
                instructors
              </li>
              <li>We do not provide surfing instruction services directly</li>
            </ul>

            <h3>Liability Disclaimer</h3>
            <ul>
              <li>
                GetSurfingLessons.com and its owners accept no liability for any
                injuries, accidents, or damages that occur during surf lessons
              </li>
              <li>
                All responsibility for the safety and conduct of lessons lies
                solely with the individual surf instructor
              </li>
              <li>
                Any claims, damages, or legal actions related to surf
                instruction must be directed to the specific instructor involved
              </li>
              <li>
                We are not responsible for the quality, safety, or content of
                instruction provided
              </li>
            </ul>

            <h3>Instructor Responsibility</h3>
            <ul>
              <li>
                Surf instructors are independent contractors responsible for
                their own insurance, certifications, and safety protocols
              </li>
              <li>
                Instructors are solely liable for any incidents, injuries, or
                damages occurring during their lessons
              </li>
              <li>
                Instructors must maintain appropriate liability insurance and
                necessary certifications
              </li>
            </ul>

            <h3>Student Acknowledgment</h3>
            <ul>
              <li>
                Students acknowledge that surfing is an inherently dangerous
                activity
              </li>
              <li>
                Participation in surf lessons is entirely at the student's own
                risk
              </li>
              <li>
                Students are responsible for their own medical insurance
                coverage
              </li>
              <li>
                Students must accurately represent their swimming ability and
                health conditions
              </li>
            </ul>

            <div className="liability-notice">
              <p>
                <strong>IMPORTANT NOTICE:</strong> By using our platform to
                connect with a surf instructor, you explicitly acknowledge that
                GetSurfingLessons.com is not liable for any physical, emotional,
                or property damage that may occur before, during, or after surf
                lessons. Our role is limited exclusively to facilitating
                connections between students and independent instructors.
              </p>
            </div>
          </div>
        </section>

        <section className="terms-section">
          <h2>6. Privacy and Data</h2>
          <p>
            We collect and process personal data as outlined in our Privacy
            Policy. By using our service, you consent to our data practices.
          </p>
        </section>

        <section className="terms-section">
          <h2>7. Intellectual Property</h2>
          <p>
            All content on GetSurfingLessons.com is protected by copyright and
            other intellectual property rights. Users may not reproduce or
            distribute site content without permission.
          </p>
        </section>

        <section className="terms-section">
          <h2>8. Modifications</h2>
          <p>
            We reserve the right to modify these terms at any time. Users will
            be notified of significant changes, and continued use of the service
            constitutes acceptance of modified terms.
          </p>
        </section>

        <section className="terms-section">
          <h2>9. Contact Information</h2>
          <p>
            For questions about these Terms of Service, please contact us at:{" "}
            <a href="mailto:getsurfinglessons@gmail.com">
              getsurfinglessons@gmail.com
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default Terms;
